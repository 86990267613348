import React from "react";
import "./App.css";
import Dashboard from "./Dashboard";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./login";
import Home from "./home";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/index" element={<Dashboard />} />
        <Route path="/dashboard" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default App;
