import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import Slidebar from "./slidebar";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import Api from "./api/api";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";

function Home() {
  const [databal, setdatabal] = useState(0);
  const [fullamountdd, setfullamount] = useState(0);

  function changedata(event, fullamountdd) {
    var value = event.target.value;
    console.log(value, fullamountdd);
    var fullamount = 17556;
    if (value) {
      var val = Number(fullamountdd) + Number(value);
      console.log(val);
      var data = fullamount - val;
      setdatabal(data);
    } else {
      setdatabal(fullamount);
    }
  }

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  function logout() {
    navigate("/");
  }

  useEffect(() => {
    const getToken = () => {
      let token = sessionStorage.getItem("token");
      if (!token) {
        navigate("/");
      }
    };

    getToken();
  }, [navigate]);

  const [showModal, setShowModal] = useState(false);
  const [botton, setbutton] = useState("save");
  const [data, SetData] = useState([]);
  const handleShow = () => {
    reset({
      name: "",
      date: "",
      amount: "",
      balance: "",
      description: "",
    });
    setShowModal(true);
    setbutton("save");
  };

  const handleClose = () => {
    setShowModal(false);
  };

  function onSubmitform(data, e) {
    axios
      .post(`${Api}/expenses/store`, data)
      .then((Response) => {
        console.log(Response);
        if (Response.status === 200) {
          Swal.fire("Expenses Added Successfully..!", "", "success");
          navigate("/dashboard");
          reset({
            name: "",
            date: "",
            amount: "",
            balance: "",
            description: "",
          });
          fetchData();
          setShowModal(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: err.message,
        });
      });
  }
  const fetchData = () => {
    fetch(`${Api}/expenses/list`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        SetData(resp.data);
        setfullamount(resp.totalamount);
        // filterClientregister(resp.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const Editexpencese = async (e, id) => {
    fetch(`${Api}/expenses/getdata/${id}`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        setbutton("update");
        setShowModal(true);
        reset(resp.data[0]);
        setdatabal(resp.data[0].balance);
        fetchData();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteregister = async (e, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${Api}/expenses/delete/${id}`).then((res) => {
          fetchData();
        });
        Swal.fire("Deleted!", "expenses has been deleted.", "success");
      }
    });
  };

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "100px",
    },
    {
      name: "date",
      selector: (row) => row.date,
      sortable: true,
      width: "150px",
    },
    {
      name: "Spent Amount",
      selector: (row) => row.amount,
      sortable: true,
      width: "150px",
    },
    {
      name: "Action",
      selector: (row) => [
        // <a
        //   onClick={(e) => ViewPaymentStatus(e, row.id)}
        //   style={{ cursor: "pointer", paddingRight: 4, color: "black" }}
        //   className="fa fa-eye"></a>,
        <span
          onClick={(e) => Editexpencese(e, row.id)}
          style={{ cursor: "pointer", paddingRight: 4, color: "black" }}>
          <i className="fa fa-pencil"></i>
        </span>,

        <span
          onClick={(e) => deleteregister(e, row.id)}
          style={{ cursor: "pointer", color: "black" }}>
          <i className="fa fa-trash"></i>
        </span>,
      ],
      width: "100px",
      fixed: "right",
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
        backgroundColor: "#fafafa",
        // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "14px",
        fontWeight: "600",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
    pagination: {
      style: {
        fontWeight: "700",
        color: "black",
      },
    },
  };

  return (
    <>
      <body id="page-top">
        <div id="wrapper">
          <Slidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Navbar />

              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                  <button
                    className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                    onClick={handleShow}>
                    <i className="fas fa-plus fa-sm text-white-50"></i> Add
                    expenses
                  </button>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Earnings (Monthly)
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                              ₹ 17,556
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-calendar fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                              Balance (Monthly)
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                              ₹ {"17556" - fullamountdd}
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                              Spend (Monthly)
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                              ₹ {fullamountdd}
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  <!-- Pending Requests Card Example --> */}
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Savings (Monthly)
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                              ₹ {"17556" - fullamountdd}
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  <!-- Content Row --> */}

                <div className="row">
                  {/*   <!-- Area Chart --> */}
                  <div className="col-xl-8 col-lg-7">
                    <div className="card shadow mb-4">
                      {/*  <!-- Card Header - Dropdown --> */}
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Expenses Overview
                        </h6>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="/"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                            aria-labelledby="dropdownMenuLink">
                            <div className="dropdown-header">
                              Dropdown Header:
                            </div>
                            <a className="dropdown-item" href="/">
                              Action
                            </a>
                            <a className="dropdown-item" href="/">
                              Another action
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/">
                              Something else here
                            </a>
                          </div>
                        </div>
                      </div>
                      {/*  <!-- Card Body --> */}
                      <div className="card-body">
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          customStyles={customStyles}
                        />
                      </div>
                    </div>
                  </div>

                  {/*  <!-- Pie Chart --> */}
                  <div className="col-xl-4 col-lg-5">
                    <div className="card shadow mb-4">
                      {/*  <!-- Card Header - Dropdown --> */}
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Revenue Sources
                        </h6>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="/"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                            aria-labelledby="dropdownMenuLink">
                            <div className="dropdown-header">
                              Dropdown Header:
                            </div>
                            <a className="dropdown-item" href="/">
                              Action
                            </a>
                            <a className="dropdown-item" href="/">
                              Another action
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/">
                              Something else here
                            </a>
                          </div>
                        </div>
                      </div>
                      {/*  <!-- Card Body --> */}
                      <div className="card-body">
                        <div className=" pt-2 pb-2">
                          <canvas id="myPieChart"></canvas>
                        </div>
                        <div className="mt-4 text-center small">
                          <span className="mr-2">
                            <i className="fas fa-circle text-primary"></i>{" "}
                            Direct
                          </span>
                          <span className="mr-2">
                            <i className="fas fa-circle text-success"></i>{" "}
                            Social
                          </span>
                          <span className="mr-2">
                            <i className="fas fa-circle text-info"></i> Referral
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb-4">
                    {/* <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Projects
                  </h6>
                </div>
                <div className="card-body">
                  <h4 className="small font-weight-bold">
                    Server Migration <span className="float-right">20%</span>
                  </h4>
                  <div className="progress mb-4">
                    <div
                      className="progress-bar bg-danger a2"
                      role="progressbar"></div>
                  </div>
                  <h4 className="small font-weight-bold">
                    Sales Tracking <span className="float-right">40%</span>
                  </h4>
                  <div className="progress mb-4">
                    <div
                      className="progress-bar bg-warning a3"
                      role="progressbar"></div>
                  </div>
                  <h4 className="small font-weight-bold">
                    Customer Database <span className="float-right">60%</span>
                  </h4>
                  <div className="progress mb-4">
                    <div className="progress-bar a7" role="progressbar"></div>
                  </div>
                  <h4 className="small font-weight-bold">
                    Payout Details <span className="float-right">80%</span>
                  </h4>
                  <div className="progress mb-4">
                    <div
                      className="progress-bar bg-info a4"
                      role="progressbar"></div>
                  </div>
                  <h4 className="small font-weight-bold">
                    Account Setup <span className="float-right">Complete!</span>
                  </h4>
                  <div className="progress">
                    <div
                      className="progress-bar bg-success a5"
                      role="progressbar"></div>
                  </div>
                </div>
              </div> */}

                    {/* <!-- Color System --> */}
                    {/* <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className="card bg-primary text-white shadow">
                    <div className="card-body">
                      Primary
                      <div className="text-white-50 small">#4e73df</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card bg-success text-white shadow">
                    <div className="card-body">
                      Success
                      <div className="text-white-50 small">#1cc88a</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card bg-info text-white shadow">
                    <div className="card-body">
                      Info
                      <div className="text-white-50 small">#36b9cc</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card bg-warning text-white shadow">
                    <div className="card-body">
                      Warning
                      <div className="text-white-50 small">#f6c23e</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card bg-danger text-white shadow">
                    <div className="card-body">
                      Danger
                      <div className="text-white-50 small">#e74a3b</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card bg-secondary text-white shadow">
                    <div className="card-body">
                      Secondary
                      <div className="text-white-50 small">#858796</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card bg-light text-black shadow">
                    <div className="card-body">
                      Light
                      <div className="text-black-50 small">#f8f9fc</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card bg-dark text-white shadow">
                    <div className="card-body">
                      Dark
                      <div className="text-white-50 small">#5a5c69</div>
                    </div>
                  </div>
                </div>
              </div> */}
                  </div>

                  <div className="col-lg-6 mb-4">
                    {/* <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Illustrations
                  </h6>
                </div>
                <div className="card-body">
                  <div className="text-center">
                    <img
                      className="img-fluid px-3 px-sm-4 mt-3 mb-4 a6"
                      src="img/undraw_posting_photo.svg"
                      alt="..."
                    />
                  </div>
                  <p>
                    Add some quality, svg illustrations to your project courtesy
                    of{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://undraw.co/">
                      unDraw
                    </a>
                    , a constantly updated collection of beautiful svg images
                    that you can use completely free and without attribution!
                  </p>
                  <a target="_blank" rel="noreferrer" href="https://undraw.co/">
                    Browse Illustrations on unDraw &rarr;
                  </a>
                </div>
              </div> */}

                    {/* <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Development Approach
                  </h6>
                </div>
                <div className="card-body">
                  <p>
                    SB Admin 2 makes extensive use of Bootstrap 4 utility
                    classNamees in order to reduce CSS bloat and poor page
                    performance. Custom CSS classNamees are used to create
                    custom components and custom utility classNamees.
                  </p>
                  <p className="mb-0">
                    Before working with this theme, you should become familiar
                    with the Bootstrap framework, especially the utility
                    classNamees.
                  </p>
                </div>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="logoutModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Ready to Leave?
                </h5>
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                Select "Logout" below if you are ready to end your current
                session.
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-dismiss="modal">
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={logout}>
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          show={showModal}
          onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Expenses
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmitform)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Name&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-sm-12">
                      <input type="hidden" name="id" {...register("id")} />
                      <select
                        className="form-control"
                        name="name"
                        id="name"
                        {...register("name", {
                          required: true,
                        })}
                        aria-invalid={errors?.name ? "true" : "false"}>
                        <option value="">Select Name</option>
                        <option value="muni">Muniraj M</option>
                        <option value="preethi">Preethi Charles</option>
                      </select>
                      {errors?.name?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Name is required</label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Date&nbsp;<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        {...register("date", { required: true })}
                        aria-invalid={errors?.date ? "true" : "false"}
                      />
                      {errors?.date?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Date is required</label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Enter amount&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        onChangeCapture={(e) => changedata(e, fullamountdd)}
                        className="form-control"
                        name="amount"
                        {...register("amount", { required: true })}
                        aria-invalid={errors?.amount ? "true" : "false"}
                      />
                      {errors?.amount?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Amount is required</label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      available balance&nbsp;
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="number"
                        className="form-control"
                        name="balance"
                        value={databal}
                        readOnly
                        {...register("balance", { required: true })}
                        aria-invalid={errors?.balance ? "true" : "false"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      For&nbsp;<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-sm-12">
                      <textarea
                        type="text"
                        className="form-control"
                        name="description"
                        {...register("description", { required: true })}
                        aria-invalid={errors?.description ? "true" : "false"}
                      />
                      {errors?.description?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">
                            Give something for this.
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Modal.Footer>
                <Button type="submit">{botton}</Button>
                <Button onClick={handleClose} className="btn btn-danger">
                  Close
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      </body>
    </>
  );
}

export default Home;
