import axios from "axios";
import React, { useEffect, useState } from "react";
import Api from "./api/api";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();

  function userlogin(data, e) {
    axios.post(`${Api}/userlogin`, data).then((Response) => {
      console.log(Response);
      if (Response.status === 200) {
        Swal.fire("Loged in Successfully..!", "Welcome Muu..", "success");
        e.target.reset();
        sessionStorage.setItem("token", Response?.token?.plainTextToken);
        navigate("/dashboard");
      }
    });
  }

  // State to store the current background image URL
  const [currentBackground, setCurrentBackground] = useState("");

  // Function to set a random background image

  // useEffect to set a random background image on component mount
  useEffect(() => {
    const backgroundImages = [
      "https://www.pixground.com/wp-content/uploads/2023/02/love-2-1024x576.jpg.webp",
      "https://wallpapers.com/images/hd/heart-background-i8fncw7b0c6a5x99.jpg",
      "https://wallpapers.com/images/hd/couple-background-qhcfh6hngvkr85z2.jpg",
      "https://c4.wallpaperflare.com/wallpaper/287/914/544/sunset-with-a-heart-wallpaper-preview.jpg",
    ];

    const setRandomBackground = () => {
      const randomIndex = Math.floor(Math.random() * backgroundImages.length);

      setCurrentBackground(backgroundImages[randomIndex]);
    };

    setRandomBackground();
    sessionStorage.clear();
  }, []);

  return (
    <>
      <div
        className="body"
        style={{
          backgroundImage: `url(${currentBackground})`,
        }}>
        <div>
          <link
            href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css"
            rel="stylesheet"
          />
          <div className="wrapper">
            <form action="" onSubmit={handleSubmit(userlogin)}>
              <h1>Login</h1>
              <div className="input-box">
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  autoComplete="off"
                  {...register("username", { required: true })}
                  aria-invalid={errors?.username ? "true" : "false"}
                />
                <i className="bx bxs-user" />
                {errors?.username?.type === "required" && (
                  <div className="text-danger text_error">
                    <label className="errlabel">Username is required</label>
                  </div>
                )}
              </div>
              <div className="input-box">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  autoComplete="new-password"
                  {...register("password", { required: true })}
                  aria-invalid={errors?.password ? "true" : "false"}
                />
                <i className="bx bxs-lock-alt" />
                {errors?.password?.type === "required" && (
                  <div className="text-danger text_error">
                    <label className="errlabel">Password is required</label>
                    <br />
                  </div>
                )}
              </div>
              {errors?.password?.type === "required" && (
                <div className="text-danger text_error">
                  <br />
                </div>
              )}
              <div className="remember-forgot">
                <label>
                  <input type="checkbox" />
                  Remember me
                </label>
                <a href="/">Forgot password?</a>
              </div>
              <button type="submit" className="btn">
                Login
              </button>
              <div className="register-link">
                <p>
                  Don't have an account? <a href="/">Register</a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
